.@{steps-prefix-cls}-item-custom {
  .@{steps-prefix-cls}-item-icon {
    height: auto;
    background: none;
    border: 0;
    > .@{steps-prefix-cls}-icon {
      top: 0;
      left: 0.5px;
      width: @steps-icon-size;
      height: @steps-icon-size;
      font-size: 24px;
      line-height: @steps-icon-size;
    }
  }
  &.@{steps-prefix-cls}-item-process {
    .@{steps-prefix-cls}-item-icon > .@{steps-prefix-cls}-icon {
      color: @process-icon-color;
    }
  }
}

// Only adjust horizontal customize icon width
.@{steps-prefix-cls} {
  &:not(.@{steps-prefix-cls}-vertical) {
    .@{steps-prefix-cls}-item-custom {
      .@{steps-prefix-cls}-item-icon {
        width: auto;
      }
    }
  }
}
