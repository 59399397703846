@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../input/style/mixin';

@mention-prefix-cls: ~'@{ant-prefix}-mention';

.@{mention-prefix-cls}-wrapper {
  .reset-component;

  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;

  .@{mention-prefix-cls}-editor {
    .input;

    display: block;
    height: auto; // To override height in .input mixin
    min-height: @input-height-base;
    padding: 0;
    line-height: @line-height-base;
    &-wrapper {
      height: auto;
      overflow-y: auto;
    }
  }
  &.@{mention-prefix-cls}-active:not(.disabled) .@{mention-prefix-cls}-editor {
    .active;
  }
  &.disabled .@{mention-prefix-cls}-editor {
    .disabled();
  }
  .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
    .public-DraftEditorPlaceholder-inner {
      height: auto;
      padding: 5px @control-padding-horizontal - 1px;
      color: @input-placeholder-color;
      white-space: pre-wrap;
      word-wrap: break-word;
      outline: none;
      opacity: 1;
    }
  }
  .DraftEditor-editorContainer .public-DraftEditor-content {
    height: auto;
    padding: 5px @control-padding-horizontal - 1px;
  }
}

.@{mention-prefix-cls}-dropdown {
  .reset-component;

  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: @zindex-dropdown;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: @component-background;
  border-radius: @border-radius-base;
  outline: none;
  box-shadow: @box-shadow-base;

  &-placement-top {
    margin-top: -0.1em;
  }

  &-notfound&-item {
    color: @disabled-color;

    .@{iconfont-css-prefix}-loading {
      display: block;
      color: @primary-color;
      text-align: center;
    }
  }
  &-item {
    position: relative;
    display: block;
    padding: 5px @control-padding-horizontal;
    overflow: hidden;
    color: @text-color;
    font-weight: normal;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background-color: @item-hover-bg;
    }

    &.focus,
    &-active {
      background-color: @item-active-bg;
    }

    &-disabled {
      color: @disabled-color;
      cursor: not-allowed;

      &:hover {
        color: @disabled-color;
        background-color: @component-background;
        cursor: not-allowed;
      }
    }

    &-selected {
      &,
      &:hover {
        color: @text-color;
        font-weight: bold;
        background-color: @background-color-base;
      }
    }

    &-divider {
      height: 1px;
      margin: 1px 0;
      overflow: hidden;
      line-height: 0;
      background-color: @border-color-split;
    }
  }
}
