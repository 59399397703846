.@{layout-prefix-cls} {
  &-sider {
    &-light {
      background: @layout-sider-background-light;
    }
    &-light &-trigger {
      color: @layout-trigger-color-light;
      background: @layout-trigger-background-light;
    }
    &-light &-zero-width-trigger {
      color: @layout-trigger-color-light;
      background: @layout-trigger-background-light;
    }
  }
}
