@import '../../style/themes/index';
@import '../../style/mixins/index';

@tab-prefix-cls: ~'@{ant-prefix}-tabs';

// card style
.@{tab-prefix-cls} {
  &&-card &-card-bar &-nav-container {
    height: @tabs-card-height;
  }
  &&-card &-card-bar &-ink-bar {
    visibility: hidden;
  }
  &&-card &-card-bar &-tab {
    height: @tabs-card-height;
    margin: 0;
    margin-right: 2px;
    padding: 0 16px;
    line-height: @tabs-card-height - 2px;
    background: @tabs-card-head-background;
    border: @border-width-base @border-style-base @border-color-split;
    border-radius: @border-radius-base @border-radius-base 0 0;
    transition: all 0.3s @ease-in-out;
  }
  &&-card &-card-bar &-tab-active {
    height: @tabs-card-height;
    color: @tabs-card-active-color;
    background: @component-background;
    border-color: @border-color-split;
    border-bottom: @border-width-base solid @component-background;
  }
  &&-card &-card-bar &-tab-inactive {
    padding: 0;
  }
  &&-card &-card-bar &-nav-wrap {
    margin-bottom: 0;
  }
  &&-card &-card-bar &-tab &-close-x {
    width: 16px;
    height: 16px;
    height: @font-size-base;
    margin-right: -5px;
    margin-left: 3px;
    overflow: hidden;
    color: @text-color-secondary;
    font-size: @font-size-sm;
    vertical-align: middle;
    transition: all 0.3s;
    &:hover {
      color: @heading-color;
    }
  }

  &&-card &-card-content > &-tabpane,
  &&-editable-card &-card-content > &-tabpane {
    transition: none !important;
    &-inactive {
      overflow: hidden;
    }
  }

  &&-card &-card-bar &-tab:hover .@{iconfont-css-prefix}-close {
    opacity: 1;
  }

  &-extra-content {
    line-height: @tabs-card-height;

    .@{tab-prefix-cls}-new-tab {
      position: relative;
      width: 20px;
      height: 20px;
      color: @text-color;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      border: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-sm;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: @tabs-card-active-color;
        border-color: @tabs-card-active-color;
      }
      svg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  // https://github.com/ant-design/ant-design/issues/4669
  &-vertical&-card &-card-bar&-left-bar,
  &-vertical&-card &-card-bar&-right-bar {
    .@{tab-prefix-cls}-nav-container {
      height: 100%;
    }
    .@{tab-prefix-cls}-tab {
      margin-bottom: 8px;
      border-bottom: @border-width-base @border-style-base @border-color-split;
      &-active {
        padding-bottom: 4px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    .@{tab-prefix-cls}-new-tab {
      width: 90%;
    }
  }

  &-vertical&-card&-left &-card-bar&-left-bar {
    .@{tab-prefix-cls}-nav-wrap {
      margin-right: 0;
    }
    .@{tab-prefix-cls}-tab {
      margin-right: 1px;
      border-right: 0;
      border-radius: @border-radius-base 0 0 @border-radius-base;
      &-active {
        margin-right: -1px;
        padding-right: 18px;
      }
    }
  }

  &-vertical&-card&-right &-card-bar&-right-bar {
    .@{tab-prefix-cls}-nav-wrap {
      margin-left: 0;
    }
    .@{tab-prefix-cls}-tab {
      margin-left: 1px;
      border-left: 0;
      border-radius: 0 @border-radius-base @border-radius-base 0;
      &-active {
        margin-left: -1px;
        padding-left: 18px;
      }
    }
  }

  // https://github.com/ant-design/ant-design/issues/9104
  & &-card-bar&-bottom-bar &-tab {
    height: auto;
    border-top: 0;
    border-bottom: @border-width-base @border-style-base @border-color-split;
    border-radius: 0 0 @border-radius-base @border-radius-base;
  }

  & &-card-bar&-bottom-bar &-tab-active {
    padding-top: 1px;
    padding-bottom: 0;
    color: @primary-color;
  }
}
