@import '../../style/themes/index';
@import '../../style/mixins/index';

@alert-prefix-cls: ~'@{ant-prefix}-alert';

@alert-message-color: @heading-color;
@alert-text-color: @text-color;
@alert-close-color: @text-color-secondary;
@alert-close-hover-color: @icon-color-hover;

.@{alert-prefix-cls} {
  .reset-component;

  position: relative;
  padding: 8px 15px 8px 37px;
  border-radius: @border-radius-base;

  &&-no-icon {
    padding: 8px 15px;
  }

  &&-closable {
    padding-right: 30px;
  }

  &-icon {
    position: absolute;
    top: 8px + @font-size-base * @line-height-base / 2 - @font-size-base / 2;
    left: 16px;
  }

  &-description {
    display: none;
    font-size: @font-size-base;
    line-height: 22px;
  }

  &-success {
    background-color: @alert-success-bg-color;
    border: @border-width-base @border-style-base @alert-success-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-success-icon-color;
    }
  }

  &-info {
    background-color: @alert-info-bg-color;
    border: @border-width-base @border-style-base @alert-info-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-info-icon-color;
    }
  }

  &-warning {
    background-color: @alert-warning-bg-color;
    border: @border-width-base @border-style-base @alert-warning-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-warning-icon-color;
    }
  }

  &-error {
    background-color: @alert-error-bg-color;
    border: @border-width-base @border-style-base @alert-error-border-color;
    .@{alert-prefix-cls}-icon {
      color: @alert-error-icon-color;
    }
  }

  &-close-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    overflow: hidden;
    font-size: @font-size-sm;
    line-height: 22px;
    cursor: pointer;

    .@{iconfont-css-prefix}-close {
      color: @alert-close-color;
      transition: color 0.3s;
      &:hover {
        color: @alert-close-hover-color;
      }
    }
  }

  &-close-text {
    position: absolute;
    right: 16px;
  }

  &-with-description {
    position: relative;
    padding: 15px 15px 15px 64px;
    color: @alert-text-color;
    line-height: @line-height-base;
    border-radius: @border-radius-base;
  }

  &-with-description&-no-icon {
    padding: 15px;
  }

  &-with-description &-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px;
  }

  &-with-description &-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: @font-size-base;
    cursor: pointer;
  }

  &-with-description &-message {
    display: block;
    margin-bottom: 4px;
    color: @alert-message-color;
    font-size: @font-size-lg;
  }

  &-with-description &-description {
    display: block;
  }

  &&-close {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transform-origin: 50% 0;
    transition: all 0.3s @ease-in-out-circ;
  }

  &-slide-up-leave {
    animation: antAlertSlideUpOut 0.3s @ease-in-out-circ;
    animation-fill-mode: both;
  }

  &-banner {
    margin-bottom: 0;
    border: 0;
    border-radius: 0;
  }
}

@keyframes antAlertSlideUpIn {
  0% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes antAlertSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
