@table-padding-vertical-md: @table-padding-vertical * 3 / 4;
@table-padding-horizontal-md: @table-padding-horizontal / 2;
@table-padding-vertical-sm: @table-padding-vertical / 2;
@table-padding-horizontal-sm: @table-padding-horizontal / 2;

.@{table-prefix-cls}-middle {
  > .@{table-prefix-cls}-title,
  > .@{table-prefix-cls}-footer {
    padding: @table-padding-vertical-md @table-padding-horizontal-md;
  }
  > .@{table-prefix-cls}-content {
    > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-body > table,
    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-body > table,
    > .@{table-prefix-cls}-fixed-left > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-right > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-left
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table,
    > .@{table-prefix-cls}-fixed-right
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table {
      > .@{table-prefix-cls}-thead > tr > th,
      > .@{table-prefix-cls}-tbody > tr > td {
        padding: @table-padding-vertical-md @table-padding-horizontal-md;
      }
    }
  }

  tr.@{table-prefix-cls}-expanded-row td > .@{table-prefix-cls}-wrapper {
    margin: -@table-padding-vertical-md -@table-padding-horizontal / 2 -@table-padding-vertical-md -
      1px;
  }
}

.@{table-prefix-cls}-small {
  border: @border-width-base @border-style-base @border-color-split;
  border-radius: @table-border-radius-base;

  > .@{table-prefix-cls}-title,
  > .@{table-prefix-cls}-footer {
    padding: @table-padding-vertical-sm @table-padding-horizontal-sm;
  }

  > .@{table-prefix-cls}-title {
    top: 0;
    border-bottom: @border-width-base @border-style-base @border-color-split;
  }

  > .@{table-prefix-cls}-content {
    > .@{table-prefix-cls}-body {
      margin: 0 @table-padding-horizontal-sm;
    }

    > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-body > table,
    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-body > table,
    > .@{table-prefix-cls}-fixed-left > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-right > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-left
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table,
    > .@{table-prefix-cls}-fixed-right
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table {
      border: 0;
      > .@{table-prefix-cls}-thead > tr > th,
      > .@{table-prefix-cls}-tbody > tr > td {
        padding: @table-padding-vertical-sm @table-padding-horizontal-sm;
      }
      > .@{table-prefix-cls}-thead > tr {
        background-color: transparent;
        border-bottom: @border-width-base @border-style-base @border-color-split;
      }
      > .@{table-prefix-cls}-thead > tr > th.@{table-prefix-cls}-column-sort {
        background-color: @table-body-sort-bg;
      }
    }

    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-scroll > .@{table-prefix-cls}-body > table,
    > .@{table-prefix-cls}-fixed-left > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-right > .@{table-prefix-cls}-header > table,
    > .@{table-prefix-cls}-fixed-left
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table,
    > .@{table-prefix-cls}-fixed-right
      > .@{table-prefix-cls}-body-outer
      > .@{table-prefix-cls}-body-inner
      > table {
      padding: 0;
    }

    .@{table-prefix-cls}-header {
      background-color: @component-background;
    }

    .@{table-prefix-cls}-placeholder,
    .@{table-prefix-cls}-row:last-child td {
      border-bottom: 0;
    }
  }

  &.@{table-prefix-cls}-bordered {
    border-right: 0;

    .@{table-prefix-cls}-title {
      border: 0;
      border-right: @border-width-base @border-style-base @border-color-split;
      border-bottom: @border-width-base @border-style-base @border-color-split;
    }

    .@{table-prefix-cls}-content {
      border-right: @border-width-base @border-style-base @border-color-split;
    }

    .@{table-prefix-cls}-footer {
      border: 0;
      border-top: @border-width-base @border-style-base @border-color-split;
      border-right: @border-width-base @border-style-base @border-color-split;
      &::before {
        display: none;
      }
    }

    .@{table-prefix-cls}-placeholder {
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }

    .@{table-prefix-cls}-thead > tr > th:last-child,
    .@{table-prefix-cls}-tbody > tr > td:last-child {
      border-right: none;
    }

    .@{table-prefix-cls}-fixed-left {
      .@{table-prefix-cls}-thead > tr > th:last-child,
      .@{table-prefix-cls}-tbody > tr > td:last-child {
        border-right: @border-width-base @border-style-base @border-color-split;
      }
    }

    .@{table-prefix-cls}-fixed-right {
      border-right: @border-width-base @border-style-base @border-color-split;
      border-left: @border-width-base @border-style-base @border-color-split;
    }
  }

  tr.@{table-prefix-cls}-expanded-row td > .@{table-prefix-cls}-wrapper {
    margin: -@table-padding-vertical-sm -@table-padding-horizontal / 2 -@table-padding-vertical-sm -
      1px;
  }
}
